* {
  box-sizing: border-box;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 1.4rem;
  font-family: Montserrat, sans-serif;
}

#__next {
  display: flex;
  height: inherit;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
a,
a:hover,
a:active,
a:visited {
  color: black;
}

button {
  outline: none;
}
