/**
 * @license
 * MyFonts Webfont Build ID 4033523, 2021-03-31T02:36:49-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Magistral-BookItalic by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/magistral/book-italic/
 * Copyright: Copyright &amp;#x00A9; 1997 ParaType Inc., ParaType Ltd. All rights reserved.
 *
 *
 *
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3d8bf3');

@font-face {
  font-family: 'Magistral-BookItalic';
  src: url('public/webFonts/MagistralBookItalic/font.woff2') format('woff2'),
    url('/webFonts/MagistralBookItalic/font.woff') format('woff');
}
